import React, { FC } from 'react'

import PageLoad from 'non-rendering/PageLoad'
import BlogFooter from 'pages/_serverRendered/Blog/shared/BlogFooter'
import Footer from 'components/MarketingSite/Footer'
import GetStartedFooter from 'pages/_serverRendered/Homepage/GetStarted'
import Header from 'components/MarketingSite/Header'
import { PrismicBlogPostResult } from 'pages/_serverRendered/Blog/types'
import ShareThis from 'pages/_serverRendered/Blog/shared/ShareThis'
import { blogPostsByAuthorPath } from 'pages/_serverRendered/Blog/utils'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'
import BlogContent from './BlogContent'

import styles from '../styles.module.scss'

const BlogPost: FC<PrismicBlogPostResult & { blog_home_data: BlogHomeData }> = (props) => {
  const { data, blog_home_data } = props

  return (
    <div>
      <PageLoad name="resources-page" />
      <Header />
      <section>
        <article>
          <header className="tw-max-w-3xl tw-my-8 tw-mx-auto">
            <h1 className="tw-text-4xl sm:tw-text-5xl tw-text-center tw-mx-6">{data.title[0].text}</h1>
          </header>
          {data.image.url && (
            <figure className="tw-my-8">
              <img
                className="tw-mx-auto tw-align-middle tw-max-w-full tw-h-auto"
                src={data.image.url}
                alt={data.image.alt || ''}
                width={data.image.dimensions?.width}
                height={data.image.dimensions?.height}
              />
            </figure>
          )}
          <BlogContent data={data} />
          <div className="tw-max-w-3xl tw-my-8 tw-mx-auto">
            <ShareThis className="tw-mx-6" url={props.url} />
          </div>
          <div className="tw-max-w-3xl tw-my-8 tw-mx-auto">
            <BlogFooter blogPost={props} />
          </div>
          <div className="tw-max-w-3xl tw-my-8 tw-mx-auto">
            <div className="tw-mx-6">
              <h2 className="tw-font-medium tw-text-2xl">{`Published by ${data.author}`}</h2>
              <p>
                {
                  'Get more out of your biggest asset: your home. Realm shows you what your home could be worth & how to access more of its potential value. '
                }
                <a
                  className="tw-text-moss hover:tw-text-moss hover:tw-underline"
                  href={blogPostsByAuthorPath(data.author)}
                >
                  {'View more posts'}
                </a>
              </p>
            </div>
          </div>
        </article>
      </section>
      <GetStartedFooter
        className={styles.getStarted}
        id="getstarted"
        title="Get started"
        data-hide-on="logged-in"
        // description="We currently cover most standalone, single family homes."
      />
      <Footer blog_home_data={blog_home_data} />
    </div>
  )
}

export default BlogPost
