import RealmLogo, { RealmLogoSmall } from 'components/MarketingSite/Header/RealmLogo'
import React, { FC } from 'react'

import styles from './HomepageLinks.module.scss'

const HomepageLinks: FC = () => (
  <div className={styles.container}>
    <div className={styles.brand}>
      <div>
        <RealmLogoSmall className={styles.logo} />
        <RealmLogo className={styles.logoDesktop} />
      </div>
      <h3>{'Get the most out of your biggest asset: your home'}</h3>
    </div>
    <div className={styles.links}>
      <div className={styles.linkGroup}>
        <a href="/">{'Home'}</a>
        <a href="/how-it-works/what-is-realm">{'What is Realm?'}</a>
        <a href="/how-it-works/renovate-with-realm">{'Renovate with Realm'}</a>
        <a href="/crawl/properties">{'Properties'}</a>
      </div>
      <div className={styles.linkGroup}>
        <a href="/resources/library" target="_blank" rel="noreferrer">
          {'Resources'}
        </a>
        <a href="/about-us">{'About'}</a>
        <a href="/about-us/careers">{'Careers'}</a>
        <a href="/compare">{'Compare'}</a>
      </div>
    </div>
  </div>
)

export default HomepageLinks
