import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'
import React, { FC } from 'react'

import styles from './BlogLinks.module.scss'

interface BlogLinksProps {
  blog_home_data?: BlogHomeData
}

const BlogLinks: FC<BlogLinksProps> = ({ blog_home_data }) => {
  return (
    <div className={styles.container}>
      <div>
        <h3>{'Browse resources'}</h3>
        <p>
          {
            'Tap into Realm’s library of resources to learn more about renovation costs, financing options, hiring vendors, and more.'
          }
        </p>
      </div>
      <div className={styles.linksContainer}>
        {blog_home_data?.data.body
          .find((e) => e.slice_type == 'footer_blog_links')
          ?.items?.map((item) => (
            <div key={item.blog_post.id}>
              <a href={`/resources/library/${item.blog_post.uid}`}>{item.title}</a>
            </div>
          ))}
      </div>
    </div>
  )
}

export default BlogLinks
